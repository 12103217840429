import React, { Component } from "react";
import Slider from "react-slick";
import {Animated} from "react-animated-css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../images/imagesBubaNIkol/image3.jpeg'
import img2 from '../../images/imagesBubaNIkol/image0.jpeg'


class HeroMain extends Component {
    render() {
      var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:2500,
        fade:true
      };
      return (
        <Slider {...settings}>
          <div id="home" className="items1">
            {/* <div className='wrap-img'>
          <img src={img1} className="img1" alt='weddingimg' />
            </div> */}
              <div className="container">
                  <div className="slide-content">
                      <div className="slide-subtitle">
                          {/* <h4>WERE GETTING MARRIED</h4> */}
                          <h4>НИЕ ЩЕ СЕ ЖЕНИМ</h4>      
                      </div>
                      <div className="slide-title">
                          {/* <h2>Save Our Date</h2> */}
                          <h2>Запазете си датата</h2>
                      </div>
                      <div className="slide-text">
                          <p>17 Септември 2023</p>
                      </div>
                      <Animated>
                          <div className="animated-circle"></div>
                      </Animated>
                  </div>
              </div>
          </div>
          <div className="items2">
          {/* <div className='wrap-img-2'>   */}
          {/* <img src={img2} className="img2" alt='weddingimg' /> */}
          {/* </div> */}
            <div className="container">
                <div className="container-1"> 
                      <div className="slide-content">
                          <div className="slide-subtitle">
                              {/* <h4>WERE GETTING MARRIED</h4> */}
                              <h4>НИЕ ЩЕ СЕ ЖЕНИМ</h4>

                          </div>
                          <div className="slide-title">
                              {/* <h2>Save Our Date</h2> */}
                              <h2>Запазете си датата</h2>
                          </div>
                          <div className="slide-text">
                              <p>17 Септемрви 2023</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                      </div>
                    </div>
                </div>
          </div>
        </Slider>
      );
    }
  }

export default HeroMain;