import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
// import strory1 from '../../images/story/img-1.jpg'
// import strory2 from '../../images/story/img-2.jpg'
import strory1 from '../../images/GeriIvan/balove-img.jpg'
import strory2 from '../../images/GeriIvan/italy-img.jpg'
// import strory3 from '../../images/story/img-3.jpg'
// import strory4 from '../../images/story/img-4.jpg'
import strory3 from '../../images/GeriIvan/predlojenie-img.jpg'
import strory4 from '../../images/GeriIvan/svatba-img.jpg'
import './style.css'


const Story = () =>{
    return(
        <div id="story" className="story-area section-padding">
            <Sectiontitle section={'Нашата история'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Нашето запознанството</h3>
                                {/* <h3>Нашата първа среща</h3> */}

                                {/* <span className="date">2017</span> */}
                                {/* <p>Най-хубавото усещане е да знаеш, че си намерил сродната си душа!
                                    Нашата история започва от 2017г., когато се запознахме в университета. До този момент и двамата бяхме скептично настроени относно историите за любовта от пръв поглед. Но още при запознанството ни усетихме по-специална връзка. 
                                </p> */}
                                {/* <div className="story-button">
                                    <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Нашата първа среща</h3>
                                {/* <span className="date">Фев 14, 2018г.</span>
                                <p>По-късно, на 14.02.2018 г. живота ни се пробърна, след като ни събраха препълнен клуб и няколко наши приятели. И така, нашата история продължава и до днес, обичащи се по-силно от всякога.
                                </p> */}
                                <div className="story-button">
                                   {/* <Link className="theme-btn" to='/'>Read More</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Предложението за брак</h3>
                                {/* <span className="date">Юни 21, 2021г.</span> */}
                                {/* <p>Винаги съм си представял, че когато дойде моментът, ще я заведа на най-красивата и романтична гледка и там ще й предложа брак. И ето, че на 21.06.2021г. това се случи. Бях толкова притеснен, че така и не успях да и кажа всичко, което бях решил. Емоциите взеха такъв превес, че дори не успях да чуя кога тя е казала “ДА”. Но смея да твърдя, че по-щастлив не съм се чувствал никога!” - Той </p> */}
                                <div className="story-button">
                                   {/* <Link className="theme-btn" to='/'>Read More</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Нашият годеж</h3>
                                {/* <span className="date"> 2021г</span> */}
                                {/* <p>“Един топъл юнски ден, докато бяхме на почивка, се случи нещо, което преобърна живота ни. Стояхме на местност, от която се виждаше сливането на морето с брега. Височината беше голяма и паниката ме обвзе. Точно по залез слънце, обръщайки се към него, видях усмивката му, докато той беше коленичил с пръстен в ръка. Бях толкова изненадана, че за момент не можах да осъзная какво се случва. Тогава той каза думите, които винаги тайно съм желала да чуя. Благодарение на тях успях да забравя страха от височината, всякаш всичко останало около нас изчезна и бяхме само аз и той. Емоциите нахлуха като ураган, но дори и за момент не се поколебах, и казах “ДА”. Този специален момент ще запомня завинаги.” - Тя</p> */}
                                <div className="story-button">
                                   {/* <Link className="theme-btn" to='/'>Read More</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;