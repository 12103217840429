import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import Sectiontitle from '../section-title'
import Notify from '../notification/notification'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import './style.css'



class Rsvp extends Component {

    state = {
        come: '',
        name: '',
        email: '',
        children: '',
        vegan: '',
        alergic: '',
        alcohol: '',
        // rsvp: '',
        // events: '',
        notes: '',
        error: {}
    }
    // console.log()


    sendEmail = (e) => {
        e.preventDefault();

        // emailjs.sendForm('service_d6g3g5q', 'template_cpxculq', e.target, 'apaSAHCbbSE_9Vtmz')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
        //   e.target.reset();
        this.setState(() => this.state)
        console.log('test')
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = async (e) => {
        e.preventDefault();

        const { 
            come,
            name,
            email,
            children,
            vegan,
            alergic,
            alcohol,
            // rsvp,
            // events,
            notes, error } = this.state;
        if(come !== 'Не') {
        if (come === '') {
            error.come = "Моля изберете от дадените опции";
        }
        if (name === '') {
            error.name = "Моля, въведете име";
        }
        if (email === '') {
            error.email = "Моля, въведете имейл";
        }
        if (children === '') {
            error.children = "Моля попълнете полето";
        }
        if (vegan === '') {
            error.vegan = "Моля изберете от дадените опции";

        }
        if (alergic === '') {
            error.alergic = "Моля попълнете полето";
            NotificationManager.error('Моля попълнете полетата преди да изпратите поканата', 'Неуспешно');

        }
        if (alcohol === '') {
            error.alcohol = "Моля изберете от дадените опции";

        }
        // if (rsvp === '') {
        //     error.rsvp = "Select your number of rsvp";
        // }
        // if (events === '') {
        //     error.events = "Select your event list";
        // }

    }
    console.log('ERR',error)

        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.email === '' && error.rsvp === '' ) {
            this.setState({
                come: '',
                name: '',
                email: '',
                children: '',
                vegan: '',
                alergic: '',
                alcohol: '',
                // rsvp: '',
                // events: '',
                notes: '',
                error: {}
            })

        }
        
           if(this.state.alergic !== '') {
            emailjs.sendForm('service_o0tdw7j', 'template_56k3fez', e.target, 'HOCuxtSN2IccX2Yt9')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            NotificationManager.info('Успешно изпратихте поканата', 'Успешно');
        };

        if(this.state.come === 'Не' && this.state.name === '') {
            NotificationManager.error('Моля въведете име преди да изпратите поканата', 'Неуспешно');
        }

        if(this.state.come === 'Не' && this.state.name !== '') {
            emailjs.sendForm('service_o0tdw7j', 'template_56k3fez', e.target, 'HOCuxtSN2IccX2Yt9')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            NotificationManager.info('Успешно изпратихте поканата', 'Успешно');
        };
        
        console.log(this.state);
        this.setState({
        come: '',
        name: '',
        email: '',
        children: '',
        vegan: '',
        alergic: '',
        alcohol: '',
        // rsvp: '',
        // events: '',
        notes: '',
        // error: {}
        })
      
        console.log(this.state.error.notes);
        
        // e.target.reset();
        
        // if(error === false) {
        //     NotificationManager.info('Успешно изпратихте поканата', 'Успешно');
        //     } if(error === true) {
        //     NotificationManager.success('Успешно изпратихте поканата', 'Успешно');
    
        //     }
    }

    render() {

        const { 
            come,
            name,
            email,
            children,
            // rsvp,
            vegan,
            alergic,
            alcohol,
            notes, error } = this.state;
       
                if((this.state.come === '' )) return (
                <div className='image-wprapper'>
                <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                    <Sectiontitle section={'Бъдете наши гости'} />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                                <div className="rsvp-wrap">
                                    <form onSubmit={this.subimtHandler}>
                                        <div className="contact-form form-style">
                                        <div className="col col-sm-12">
                                                <select className="form-control" onChange={this.changeHandler} value={come} name="come">
                                                    <option disabled value="">Ще присъствате ли?*</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Не">Не</option>
                                                </select>
                                                {/* <p>{error.come ? error.come : ''}</p> */}
                                            </div>
                                            {/* {if(this.state.come) === 'Да' ( */}
                                            {/* <>  */}
                                                    <div className="col-12 col-sm-12">
                                                        <input type="text" value={name} onChange={this.changeHandler} placeholder="Име*" id="fname" name="name" />
                                                        <p>{error.name ? error.name : ''}</p>
                                                    </div><div className="col-12  col-sm-12">
                                                        <input type="text" placeholder="Имейл*" onChange={this.changeHandler} value={email} id="email" name="email" />
                                                        <p>{error.email ? error.email : ''}</p>
                                                    </div><div className="col-12  col-sm-12">
                                                        <input type="text" placeholder="Ще има ли деца?*" onChange={this.changeHandler} value={children} id="children" name="children" />
                                                        <p>{error.children ? error.children : ''}</p>
                                                    </div><div className="col col-sm-12">
                                                        <select className="form-control" onChange={this.changeHandler} value={alcohol} name="alcohol">
                                                            <option disabled value="">Алкохол*</option>
                                                            <option value="Вино">Вино</option>
                                                            <option value="Водка">Водка</option>
                                                            <option value="Уиски">Уиски</option>
                                                            <option value="Ракия">Ракия</option>
    
                                                        </select>
                                                        <p>{error.alcohol ? error.alcohol : ''}</p>
                                                    </div><div className="col col-sm-12">
                                                        <select className="form-control" onChange={this.changeHandler} value={vegan} name="vegan">
                                                            <option disabled value="">Вегетарианец ли сте?*</option>
                                                            <option value="Да">Да</option>
                                                            <option value="Не">Не</option>
                                                        </select>
                                                        <p>{error.vegan ? error.vegan : ''}</p>
                                                    </div><div className="col-12  col-sm-12">
                                                        <input type="text" placeholder="Имате ли хранителни алергии?*" onChange={this.changeHandler} value={alergic} id="alergic" name="alergic" />
                                                        <p>{error.alergic ? error.alergic : ''}</p>
                                                    </div><div className="col-12 col-sm-12">
                                                        <textarea className="contact-textarea" value={notes} onChange={this.changeHandler} placeholder="Допълнителна информация" name="notes"></textarea>
                                                        {/* <p>{error.notes ? error.notes : ''}</p> */}
                                                    </div><div className="col-12 text-center">
                                                        <button id="submit" type="submit" className="submit">Изпратете поканата</button>
                                                    </div>
                                                    {/* </>  */}
                                             {/* )} */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    <h1 style={{color: "white", textAlign: 'center'}}>Молим Ви да ни дадете отговор, дали ще присъставте до 01.09.2023г.</h1>

                    </div>
                    <NotificationContainer/>
                </div>
                </div>
            )
           else if(this.state.come === 'Да' ) {
                return (
                    <>
 <div className='image-wprapper'>
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <Sectiontitle section={'Бъдете наши гости'} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="rsvp-wrap">
                                <form onSubmit={this.subimtHandler}>
                                    <div className="contact-form form-style">
                                    <div className="col col-sm-12">
                                            <select className="form-control" onChange={this.changeHandler} value={come} name="come">
                                                <option disabled value="">Ще присъствате ли?*</option>
                                                <option value="Да">Да</option>
                                                <option value="Не">Не</option>
                                            </select>
                                            {/* <p>{error.come ? error.come : ''}</p> */}
                                        </div>
                                        {/* {if(this.state.come) === 'Да' ( */}
                                        {/* <>  */}
                                                <div className="col-12 col-sm-12">
                                                    <input type="text" value={name} onChange={this.changeHandler} placeholder="Име*" id="fname" name="name" />
                                                    <p>{error.name ? error.name : ''}</p>
                                                </div><div className="col-12  col-sm-12">
                                                    <input type="text" placeholder="Имейл*" onChange={this.changeHandler} value={email} id="email" name="email" />
                                                    <p>{error.email ? error.email : ''}</p>
                                                </div><div className="col-12  col-sm-12">
                                                    <input type="text" placeholder="Ще има ли деца?*" onChange={this.changeHandler} value={children} id="children" name="children" />
                                                    <p>{error.children ? error.children : ''}</p>
                                                </div><div className="col col-sm-12">
                                                    <select className="form-control" onChange={this.changeHandler} value={alcohol} name="alcohol">
                                                        <option disabled value="">Алкохол*</option>
                                                        <option value="Вино">Вино</option>
                                                        <option value="Водка">Водка</option>
                                                        <option value="Уиски">Уиски</option>
                                                        <option value="Ракия">Ракия</option>

                                                    </select>
                                                    <p>{error.alcohol ? error.alcohol : ''}</p>
                                                </div><div className="col col-sm-12">
                                                    <select className="form-control" onChange={this.changeHandler} value={vegan} name="vegan">
                                                        <option disabled value="">Вегетарианец ли сте?*</option>
                                                        <option value="Да">Да</option>
                                                        <option value="Не">Не</option>
                                                    </select>
                                                    <p>{error.vegan ? error.vegan : ''}</p>
                                                </div><div className="col-12  col-sm-12">
                                                    <input type="text" placeholder="Имате ли хранителни алергии?*" onChange={this.changeHandler} value={alergic} id="alergic" name="alergic" />
                                                    <p>{error.alergic ? error.alergic : ''}</p>
                                                </div><div className="col-12 col-sm-12">
                                                    <textarea className="contact-textarea" value={notes} onChange={this.changeHandler} placeholder="Допълнителна информация" name="notes"></textarea>
                                                    {/* <p>{error.notes ? error.notes : ''}</p> */}
                                                </div><div className="col-12 text-center">
                                                    <button id="submit" type="submit" className="submit">Изпратете поканата</button>
                                                </div>
                                                {/* </>  */}
                                         {/* )} */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
            </div>
        )
                </>
                )
            } else if((this.state.come === 'Не' )) {
                return (
                    <div className='image-wprapper'>
                    <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                        <Sectiontitle section={'Бъдете наши гости'} />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                                    <div className="rsvp-wrap">
                                        <form onSubmit={this.subimtHandler}>
                                            <div className="contact-form form-style">
                                           
                                                {/* {if(this.state.come) === 'Да' ( */}
                                                {/* <>  */}
                                                <div className="col col-sm-12">
                                            <select className="form-control" onChange={this.changeHandler} value={come} name="come">
                                                <option disabled value="">Ще присъствате ли?*</option>
                                                <option value="Да">Да</option>
                                                <option value="Не">Не</option>
                                            </select>
                                            {/* <p>{error.come ? error.come : ''}</p> */}
                                        </div>
                                                        <div className="col-12 col-sm-12">
                                                            <input type="text" value={name} onChange={this.changeHandler} placeholder="Име*" id="fname" name="name" />
                                                            <p>{error.name ? error.name : ''}</p>
                                                        </div>
                                                      
                                                        <div className="col-12 text-center">
                                                    <button id="submit" type="submit" className="submit">Изпратете поканата</button>
                                                </div>
                                                        {/* </>  */}
                                                 {/* )} */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <NotificationContainer/>
                    </div>
                    </div>
                )
            }          
    }
}

export default Rsvp;